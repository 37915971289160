import React, {useContext} from "react";
import { captureException } from "@sentry/react";
import { get } from "lodash";
import useAxios from "axios-hooks";
import { Button } from "reactstrap";
import { useIntl } from "react-intl";
import { Select } from "../components/controls";
import { PK_PLACEHOLDER } from "../constants";
import { getQueryArgs } from "../utils";
import PropTypes from "prop-types";
import ConfigWrapper, {ConfigContext} from "../ConfigWrapper.jsx";

const EmptyDecorator = ({ children }) => <>{children}</>;

EmptyDecorator.propTypes = {
    children: PropTypes.node
}
const MenuButtonDecorator = ({ children }) => (
  <div className="d-grid">{children}</div>
);

MenuButtonDecorator.propTypes = {
    children: PropTypes.node
}

const SpacedDecorator = ({ children }) => (
  <div className="mb-20">{children}</div>
);

SpacedDecorator.propTypes = {
    children: PropTypes.node
}

const HideDecorator = () => {
  return null;
};

const HomeBannerSelectDecorator = ({ children }) => (
  <div className="col-12 col-md-4 mb-10 mb-md-0">{children}</div>
);

HomeBannerSelectDecorator.propTypes = {
    children: PropTypes.node
}

const HomeBannerButtonDecorator = ({ children }) => (
  <div className="col-12 col-md-4">{children}</div>
);

HomeBannerButtonDecorator.propTypes = {
    children: PropTypes.node
}

const HolsterFinder = ({
  ManufacturerDecorator = EmptyDecorator,
  ModelDecorator = EmptyDecorator,
  ButtonDecorator = EmptyDecorator,
  anchorPrefix = "",
  showCount = false
}) => {
  const configData = useContext(ConfigContext);

  if (!configData) {
    return null;
  }

  return <HolsterFinderWrapped
      configData={configData}
      ManufacturerDecorator={ManufacturerDecorator}
      ModelDecorator={ModelDecorator}
      ButtonDecorator={ButtonDecorator}
      anchorPrefix={anchorPrefix}
      showCount={showCount}
  />
}

const HolsterFinderCommonProps = {
    config: PropTypes.string,
    ManufacturerDecorator: PropTypes.func,
    ModelDecorator: PropTypes.func,
    ButtonDecorator: PropTypes.func,
    anchorPrefix: PropTypes.string
}

HolsterFinder.propTypes = HolsterFinderCommonProps

const HolsterFinderWrapped = ({
  configData,
  ManufacturerDecorator = EmptyDecorator,
  ModelDecorator = EmptyDecorator,
  ButtonDecorator = EmptyDecorator,
  anchorPrefix = "",
  showCount = false
}) => {
  const { messages } = useIntl();
  const [isMobileScreen, setMobileScreen] = React.useState(
    window.matchMedia("(max-width: 767px)").matches
  );

  const [
    { data: manufacturers /* loading: manufLoading */, error: manufError },
  ] = useAxios({
    url: configData.manufacturers_url || '',
  });

  React.useEffect(() => {
    const status = get(manufError, "response.status", 0);
    if (manufError && status !== 403 && status !== 499) {
      captureException(manufError);
    }
  }, [manufError]);

  const [
    { data: models /* loading: modelsLoading */, error: modelsError },
    fetchModels,
  ] = useAxios({
    url: '',
  }, {manual: true});

  React.useEffect(() => {
    const status = get(modelsError, "response.status", 0);
    if (modelsError && status !== 403) {
      captureException(modelsError);
    }
  }, [modelsError]);

  const onMatchChange = (evt) => {
    setMobileScreen(evt.matches);
  };

  React.useEffect(() => {
    const query = window.matchMedia("(max-width: 767px)");

    if (query.addEventListener) {
      query.addEventListener("change", onMatchChange);
    } else if (query.addListener) {
      query.addListener(onMatchChange);
    }

    return () => {
      const query2 = window.matchMedia("(max-width: 767px)");
      if (query2.removeEventListener) {
        query2.removeEventListener("change", onMatchChange);
      } else if (query2.removeListener) {
        query2.removeListener(onMatchChange);
      }
    };
  }, []);

  const [manufacturerUrls, setManufacturerUrls] = React.useState({});
  const [currentManufacturer, setCurrentManufacturer] = React.useState(null);
  const [currentModel, setCurrentModel] = React.useState(null);
  const [searchTarget, setSearchTarget] = React.useState(null);


  React.useEffect(() => {
    if (manufacturers&& manufacturers.items) {
      const newUrls = {};
      manufacturers.items.forEach((m) => {
        newUrls[m.value] = m.url;
      });
      setManufacturerUrls(newUrls);
    }
  }, [manufacturers]);

  React.useEffect(() => {
    if (currentManufacturer) {
      const { wearing_style, material } = configData;
      const queryArgs = getQueryArgs({ wearing_style, material });
      fetchModels({
        url:
          configData.models_url.replace(PK_PLACEHOLDER, currentManufacturer) +
          queryArgs,
      });
    }
  }, [currentManufacturer, fetchModels, configData]);

  React.useEffect(() => {
    const manufacturer = get(configData, "initial.manufacturer", null);
    const model = get(configData, "initial.model", null);
    if (manufacturer && model) {
      setCurrentManufacturer(manufacturer);
      setCurrentModel(model);
    }
    if (configData.preset_initial) {
      setCurrentManufacturer(configData.preset_initial);
    }
  }, [configData]);

  const updateSearchTarget = () => {
    if (models.items) {
        const model = models.items.find((m) => {
            return m.value === currentModel;
        });
        if (model) {
            if (model.restricted.count === 0) {
                setSearchTarget(model.unrestricted);
            } else {
                setSearchTarget(model.restricted);
            }
        }
    }
  };

  React.useEffect(() => {
    if (!currentModel) {
      setSearchTarget(null);
    }
    if (models && models.items) {
      updateSearchTarget();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentModel]);

  React.useEffect(() => {
    if (models && models.items && currentModel) {
      updateSearchTarget();
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [models, currentModel]);

  const onManufacturerChanged = (manufacturerId) => {
    setCurrentManufacturer(manufacturerId);
    setCurrentModel(null);
  };

  const onModelChanged = (modelId) => {
    setCurrentModel(modelId);
  };

  const onSearch = () => {
    if (!currentModel && manufacturerUrls[currentManufacturer]) {
      window.location = manufacturerUrls[currentManufacturer];
    } else if (searchTarget) {
      window.location = searchTarget.url;
    }
  };

  const focusDropdown = (scrollAnchor) => {
    if (isMobileScreen) {
      const element = document.getElementById(scrollAnchor);
      if (element) {
        // zrusene 9.10.2022, nepaci sa im
        // element.scrollIntoView();
      }
    }
  };

  const manufacturerDropdownId = `${anchorPrefix}-manufacturer`;
  const modelDropdownId = `${anchorPrefix}-model`;

  return (
    <>
      <ManufacturerDecorator>
        <Select
          options={manufacturers ? manufacturers.items : []}
          style={{ height: "40px" }}
          value={currentManufacturer}
          onChange={(id, value) => {
            onManufacturerChanged(value);
          }}
          placeholder={messages["holsterfinder.armory.select.placeholder"]}
          id={manufacturerDropdownId}
          onFocus={() => focusDropdown(manufacturerDropdownId)}
        />
      </ManufacturerDecorator>
      <ModelDecorator>
        <Select
          options={models ? models.items : []}
          style={{ height: "40px" }}
          onChange={(id, value) => {
            onModelChanged(value);
          }}
          value={currentModel}
          placeholder={messages["holsterfinder.model.select.placeholder"]}
          id={modelDropdownId}
          onFocus={() => focusDropdown(modelDropdownId)}
        />
      </ModelDecorator>
      <ButtonDecorator>
        <Button onClick={onSearch} color="primary" className="w-100">
          <span className="icon icon--regular icon--search me-10"> </span>
          Find Holsters{searchTarget && ` (${searchTarget.count})`}
        </Button>
      </ButtonDecorator>
      {showCount ?
        <a className="text-bold text-no-underline d-block mt-40 ps-10 ps-md-0"
           href="/holsters">
          <span className="text-underline">Shop all holsters</span>
          <span className="text-sm text-muted text-regular">{` (${configData.all_holsters_count})`}</span>
          <span className="icon icon--regular icon--arrow-right ms-10"></span>
        </a> : null}
    </>
  );
};

HolsterFinderWrapped.propTypes = {
  configData: PropTypes.object,
  ManufacturerDecorator: PropTypes.func,
  ModelDecorator: PropTypes.func,
  ButtonDecorator: PropTypes.func,
  anchorPrefix: PropTypes.string
}

const MenuHolsterFinder = ({config}) => (
  <ConfigWrapper config={config}>
    <HolsterFinder
      ManufacturerDecorator={SpacedDecorator}
      ModelDecorator={SpacedDecorator}
      ButtonDecorator={MenuButtonDecorator}
      anchorPrefix="menu"
      showCount
    />
  </ConfigWrapper>
);

MenuHolsterFinder.propTypes = HolsterFinderCommonProps;

const HomeHolsterFinder = ({ config }) => (
  <div className="row px-10 px-xl-250 text-center">
    <ConfigWrapper config={config}>
      <HolsterFinder
        ManufacturerDecorator={HomeBannerSelectDecorator}
        ModelDecorator={HomeBannerSelectDecorator}
        ButtonDecorator={HomeBannerButtonDecorator}
        anchorPrefix="home"
      />
    </ConfigWrapper>
  </div>
);

HomeHolsterFinder.propTypes = HolsterFinderCommonProps;

const BannerHolsterFinder = ({ config }) => (
  <div className="row px-10 px-xl-250 text-center mb-20 mb-md-30">
    <ConfigWrapper config={config}>
      <HolsterFinder
        ManufacturerDecorator={HomeBannerSelectDecorator}
        ModelDecorator={HomeBannerSelectDecorator}
        ButtonDecorator={HomeBannerButtonDecorator}
        anchorPrefix="banner"
      />
    </ConfigWrapper>
  </div>
);

BannerHolsterFinder.propTypes = HolsterFinderCommonProps;

const ArmoryHolsterFinder = ({ config }) => (
  <div className="row px-10 px-xl-250 text-center mb-20 mb-md-30 justify-content-center">
    <ConfigWrapper config={config}>
      <HolsterFinder
        ManufacturerDecorator={HideDecorator}
        ModelDecorator={HomeBannerSelectDecorator}
        ButtonDecorator={HomeBannerButtonDecorator}
        anchorPrefix="banner"
      />
    </ConfigWrapper>
  </div>
);

ArmoryHolsterFinder.propTypes = HolsterFinderCommonProps;

export {
  MenuHolsterFinder,
  HomeHolsterFinder,
  BannerHolsterFinder,
  ArmoryHolsterFinder,
};
